import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Footer from "../components/homepage/Footer"

const Delivery = () => {
  return (
    <Layout>
      <Header layout="layout-2" />

      <div style={{ height: "36px" }} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Chính sách giao nhận
        </Text>
        <p>
          Sản phẩm dịch vụ kỹ thuật số của chúng tôi sẽ được cung cấp ngay khi
          bạn tạo tài khoản hoặc đăng ký gói dịch vụ
        </p>

        <div style={{ height: "128px" }} />
      </Div>
      <Footer />
    </Layout>
  )
}

export default Delivery
